import React from "react"
import { Container, Row, Tab, Nav, Col } from "react-bootstrap"
import SetUpInstructions from "./setUp-instructions"

const StepOneTelegram = () => (
  <p>
    Invite <span>@CryptoFiBot</span> to your telegram group. Make sure that
    FiBot is allowed to send messages and links to your telegram group.
  </p>
)
const StepOneDiscord = () => (
  <div>
    <p>Use the following link to add FiBot to your Discord group.</p>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://discord.com/api/oauth2/authorize?client_id=839903022850244618&permissions=259846043712&scope=bot"
      className="rounded-pill mt-2 px-4 btn btn-variant btn-md"
    >
      Add to Discord
    </a>
  </div>
)

function SetUp() {
  return (
    <Container>
      <Row>
        <div className="display-4 col-12 heading mt-3 section-heading">
          <span>Set up</span>
        </div>
        <Col
          md={6}
          className="d-flex flex-column justify-content-center main-description grow-content"
        >
          <p className="mb-5">
            Get in touch with us now and add Fibot to your project.
          </p>
        </Col>
      </Row>

      <Tab.Container
        defaultActiveKey="telegram"
        className="mb-4 col-12 how-to-buy-tabs"
      >
        <Col>
          <Nav
            variant="pills"
            className="d-flex justify-content-around how-to-buy-tabs"
          >
            <Nav.Item>
              <Nav.Link eventKey="telegram">Telegram</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="discord">Discord</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col>
          <Tab.Content>
            <Tab.Pane eventKey="telegram">
              <SetUpInstructions
                step1={<StepOneTelegram />}
                step2={
                  <p>
                    Use the <span>/settoken</span> command to configure the
                    contract address that you want to see price information for.
                    <br />
                    E.g.:{" "}
                    <span>
                      /settoken bsc:0x935607e39117435d35c0750af4d043124d287fd4
                    </span>
                  </p>
                }
                step3={
                  <p>
                    Use the <span>/price</span> and <span>/chart</span> commands
                    to see real time price information on your token. For more
                    information use the <span>/help</span> command and FiBot
                    will help you out.
                  </p>
                }
              />
            </Tab.Pane>
            <Tab.Pane eventKey="discord">
              <SetUpInstructions
                step1={<StepOneDiscord />}
                step2={
                  <p>
                    Use the <span>!settoken</span> command to configure the
                    contract address that you want to see price information for.
                    <br />
                    E.g.:{" "}
                    <span>
                      !settoken bsc:0x935607e39117435d35c0750af4d043124d287fd4
                    </span>
                  </p>
                }
                step3={
                  <p>
                    Use the <span>!price</span> and <span>!chart</span> commands
                    to see real time price information on your token. For more
                    information use the <span>!help</span> command and FiBot
                    will help you out.
                  </p>
                }
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </Container>
  )
}

export default SetUp
