import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Col, Row } from "react-bootstrap"

import SetUp from "../components/setUp"
import Charts from "../components/charts"


const PriceBotPage = () => (
  <Layout pageInfo={{ pageName: "price-bot" }}>
    <Seo
      title="DeFi Price Bot"
      keywords={[`crypto`, `FIBot`, `defi`, `FIBot`, `cryptoFIBot`, `bot`]}
    />
     <Row className="g-0">
      <Col>
        <Container className="mt-5">
          <Charts />
        </Container>
      </Col>
    </Row>
    <Row className="g-0">
      <Col>
        <Container className="mt-5">
          <SetUp />
        </Container>
      </Col>
    </Row>
  </Layout>
)

export default PriceBotPage;
