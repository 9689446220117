import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

function SetUpInstructions({step1, step2, step3}) {
  return (
    <Container>
      <Row className="justify-content-between">
        <Col md={4} className="d-flex mb-4">
          <Card className="w-100 price-card">
            <Card.Body className="d-flex flex-wrap my-1 align-content-start">
              <Col md={12} className="text-center mb-1">
                <h2 className="card-title">1</h2>
              </Col>
              <Col md={12} className="text-center">
                  {step1}
              </Col>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="d-flex  mb-4">
          <Card className="w-100 price-card">
            <Card.Body className="d-flex flex-wrap my-1 align-content-start">
              <Col md={12} className="text-center mb-1">
                <h2 className="card-title">2</h2>
              </Col>
              <Col md={12} className="text-center">
                    {step2}
              </Col>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="d-flex  mb-4">
          <Card className="w-100 price-card">
            <Card.Body className="d-flex flex-wrap my-1 align-content-start">
              <Col md={12} className="text-center mb-1">
                <h2 className="card-title">3</h2>
              </Col>
              <Col md={12} className="text-center">
                    {step3}
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SetUpInstructions;
