import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Col, Row } from "react-bootstrap"

function Charts() {
  return (
    <Container>
      <Row className="g-0">
        <Col md={12}>
          <div className="display-4 col-12 mt-3 section-heading">
            <span>Price Bot</span>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-between charts-content g-0">
        <Col
          md={6}
          className="pl-md-5  d-flex flex-column justify-content-center"
        >
          <div className="main-description">
            <p>
              Offer your community the complete picture of live price movements
              and empower your investors to determine when is the best time to
              make a trade. In addition to a beautiful candlestick or line chart
              representation, FiBot can show various key performance statistics.
            </p>

            <p>
              FiBot is a price bot that works on Discord and Telegram and can be
              setup for any coin on the bsc, eth, heco and matic networks.
            </p>

            <p>
              Boost your project's trading volume on dex platforms like uniswap
              or pancakeswap with Fibot's market making features.
            </p>
          </div>
        </Col>
        <Col md={6} className="d-flex justify-content-center">
          <StaticImage
            quality="100"
            className="chart-image"
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
            src="../images/double-charts.png"
            alt="crypto price bot chart bsc eth heco defi price bot"
            placeholder="blurred"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Charts
